<template>
    <div>
        <ts-page-title
            :title="$t('incentivePerformanceByCo.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('incentivePerformanceByCo.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="row">
                    <div class="col-lg-3">
                        <div class="card border-0 bg-gray-900">
                            <div class="card-body">
                                <div class="text-gray-500">
                                    <b>{{
                                        $t(
                                            "incentivePerformanceByCo.filterCriteria"
                                        )
                                    }}</b>
                                </div>
                            </div>
                            <div class="widget-list rounded-bottom">
                                <div class="mb-3 mt-3">
                                    <div class="col-md-12">
                                        <label
                                            class="form-col-label control-label"
                                            >{{
                                                $t(
                                                    "incentivePerformanceByCo.branch"
                                                )
                                            }}</label
                                        >
                                        <ts-branch-filter
                                            @filter="
                                                value =>
                                                    (model.branch_id = value)
                                            "
                                            :isPlaceholder="true"
                                        />
                                        <div
                                            class="text-danger"
                                            v-if="errors.has('branch_id')"
                                        >
                                            {{ errors.first("branch_id") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="col-md-12">
                                        <label
                                            class="form-col-label control-label"
                                            >{{
                                                $t(
                                                    "incentivePerformanceByCo.co"
                                                )
                                            }}</label
                                        >
                                        <Select
                                            v-model="model.employee_id"
                                            multiple
                                            :max-tag-count="1"
                                            :filterable="true"
                                            :filter-by-label="true"
                                            :placeholder="$t('all')"
                                        >
                                            <Option
                                                v-for="item in employees"
                                                :value="item.employee_id"
                                                :key="item.employee_id"
                                                :label="item.employee_name_en"
                                            >
                                                {{ item.employee_name_en }}
                                            </Option>
                                        </Select>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('employee_id')"
                                        >
                                            {{ errors.first("employee_id") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="col-md-12">
                                        <label
                                            class="form-col-label control-label"
                                            >{{
                                                $t(
                                                    "incentivePerformanceByCo.transactionDate"
                                                )
                                            }}</label
                                        >
                                        <DatePicker
                                            :value="model.cycle_month"
                                            type="month"
                                            placeholder="Select Month"
                                            style="width:100%"
                                            @on-change="cycleMonthChange"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'cycle_month'
                                                )
                                            }"
                                        >
                                        </DatePicker>
                                        <div
                                            class="text-danger"
                                            v-if="errors.has('dateRange')"
                                        >
                                            {{ errors.first("dateRange") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="col-md-12">
                                        <label
                                            class="form-col-label control-label required"
                                            >{{
                                                $t(
                                                    "loanTransactionSummary.currency"
                                                )
                                            }}</label
                                        ><br />
                                        <RadioGroup
                                            v-model="model.currency_id"
                                            size="small"
                                        >
                                            <Radio
                                                class="mt-1"
                                                :label="c.currency_id"
                                                border
                                                v-for="(c, index) in currencies"
                                                :key="index"
                                            >
                                                {{ c.currency_code }}
                                            </Radio>
                                        </RadioGroup>
                                        <div
                                            class="text-danger"
                                            v-if="errors.has('currency_id')"
                                        >
                                            {{ errors.first("currency_id") }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="mb-3 tw-justify-end tw-flex tw-space-x-2"
                                >
                                    <ts-button
                                        outline
                                        color="success"
                                        @click.prevent="exportExcel"
                                        :waiting="exporting"
                                    >
                                        <i
                                            class="far fa-file-excel"
                                            v-if="!exporting"
                                        ></i>
                                        {{ $t("exportExcel") }}</ts-button
                                    >
                                    <ts-button
                                        color="danger"
                                        outline
                                        @click.prevent="preview"
                                        :waiting="waiting"
                                    >
                                        <i
                                            class="far fa-file-pdf"
                                            v-if="!waiting"
                                        ></i>
                                        {{ $t("previewPdf") }}</ts-button
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div id="container">
                            <ts-preview-pdf v-model="waiting" :src="src" />
                        </div>
                    </div>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";
import moment from "moment";
import * as FileDownload from "downloadjs";
import { trim } from "lodash";

export default {
    name: "incentivePerformanceByCo",
    data() {
        return {
            waiting: false,
            exporting: false,
            errors: new Errors(),
            src: "",
            model: {
                branch_id: [],
                employee_id: [],
                currency_id: null,
                cycle_month: moment().format('YYYY-MM'),
            }
        };
    },
    created() {
        this.model.currency_id = this.$store.state.authUser.sys_setting[0].default_currency_id;
    },
    computed: {
        ...mapState("report/resource", ["employees", "currencies"])
    },
    methods: {
        ...mapActions("report/resource", ["getEmployee", "getCurrency"]),
        preview() {
            this.errors = new Errors();
            this.waiting = true;
            this.src = "";
            this.$store
                .dispatch(
                    "report/creditOperation/incentivePerformanceByCo",
                    this.model
                )
                .then(response => {
                    this.src = response.url;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        exportExcel() {
            this.errors = new Errors();
            this.exporting = true;

            this.$store
                .dispatch(
                    "report/creditOperation/incentivePerformanceByCoExportExcel",
                    this.model
                )
                .then(response => {
                    let filename = trim(
                        response.headers["content-disposition"]
                            .substring(
                                response.headers["content-disposition"].indexOf(
                                    "filename"
                                )
                            )
                            .replace("filename=", ""),
                        '"'
                    );
                    FileDownload(response.data, filename);
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    );
                    this.notice({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => (this.exporting = false));
        },
        cycleMonthChange(value) {
            this.model.cycle_month = value
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "INCENTIVE PERFORMANCE BY CO",
                desc: not.text
            });
        }
    },
    watch: {
        "model.branch_id": debounce(function(value) {
            this.model.employee_id = [];
            this.getEmployee({
                branch_id: value
            });
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.getCurrency();
        });
    }
};
</script>
